/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Basic CSS for apps built with Primeng */

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
.empty_state {
    height: 70%;
    display: flex;
    justify-content: center;
    .spinner {
        width: 45px;
        height: 45px;
        margin: auto;
    }
}

swiper-container {
    --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
    --swiper-pagination-color: var(--ion-color-primary, #0054e9);
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

@media only screen and (max-width: 949px) {
    .menu-header,
    .footer {
        display: none !important;
    }
}

@font-face {
    font-family: "fredoka";
    font-style: normal;
    font-weight: normal;
    src: url("assets/fonts/Fredoka/static/Fredoka-Regular.ttf");
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 170px;
    width: 400px;
    margin: 0 auto;
}

b {
    width: 100%;
}

.ripple-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    user-select: none;
    // width: 100%;
    height: 50px;
    border-radius: 8px;
}

.custom-parent {
    color: var(--ion-color-medium);
}

.custom-ripple {
    color: var(--ion-color-secondary);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
div.infos,
div.text,
div.title,
div.description,
a,
b,
li,
ul,
button,
input,
ion-text,
ion-input,
ion-textarea,
ion-button,
ion-toolbar,
ion-badge,
ion-label,
ion-datetime,
ion-note {
    font-family: 'fredoka';
}

.fixed {
    padding: 5px 3cm !important;
    position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    background-color: #fff !important;
    z-index: 100000 !important;
}


.error_message {
  color: var(--ion-color-danger);
  font-size: 14px;
  margin-top: 5px;
}

.error_input {
  border:2px solid var(--ion-color-danger) !important;
}
